"use client";

import { ChakraProvider } from "@chakra-ui/react";
import { HydrationBoundary, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { UpsightThemeProvider } from "@upsightdevs/upsight-design-system";
import { ReactNode, useState } from "react";

import { theme } from "@/app/theme";
interface ProvidersProps {
  children: ReactNode;
  dehydratedState?: unknown;
}

export const QueryProviders = ({ children, dehydratedState }: ProvidersProps) => {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={dehydratedState}>{children}</HydrationBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export const ChakraProvier = ({ children }: { children: ReactNode }) => {
  return (
    <ChakraProvider theme={theme}>
      <UpsightThemeProvider>{children}</UpsightThemeProvider>
    </ChakraProvider>
  );
};

import { alertAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    fontSize:"0.875rem",
    fontWeight:500,
    lineHeight:"140%",
    "& button": {
      display:"flex",
      height:"36px",
      p:"0",
      cursor:"pointer",
      justifyContent:"center",
      alignItems:"center",
      "& svg":{
        stroke:"#ffffff",
        path:"24px"
      }
    },
  },
  icon: {
    w: "0",
    h: "0"
  }
});

const success = definePartsStyle({
  container: {
    w: "320px",
    h: "44px",
    borderRadius: "8px",
    backgroundColor: mode("green.500", "green.500"),
    color: "white",
    cursor: "pointer",
  },
});

const error = definePartsStyle({
  container: {
    w: "320px",
    h: "44px",
    borderRadius: "8px",
    backgroundColor: mode("red.600", "red.600"),
    color: "white",
    cursor: "pointer",
  },
});

const warning = definePartsStyle({
  container: {
    w: "320px",
    h: "44px",
    borderRadius: "8px",
    backgroundColor: mode("orange.600", "orange.600"),
    color: "white",
    cursor: "pointer",
  },
});

const info = definePartsStyle({
  container: {
    w: "320px",
    h: "44px",
    borderRadius: "8px",
    backgroundColor: mode("blue.900", "blue.900"),
    boxShadow: "0px 0px 10px 5px rgba(136, 136, 136, 0.15)",
    color: "white",
    cursor: "pointer",
  },
});

export const alertTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    success,
    error,
    warning,
    info,
  },
});
